<template>
  <v-dialog v-model="isModalOpen" width="900" persistent>
    <v-form ref="price-form" v-model="is_valid">
      <v-card v-if="inboundItem">
        <v-toolbar class="py-2" flat>
          <v-toolbar-title>
            <h2 class="font-weight-regular text-h3">
              {{ $t("update_prices") }}
            </h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-0">
            <v-icon @click="close()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-8">
          <div class="d-flex flex-row">
            <v-img
              class="me-6"
              max-height="200"
              max-width="200"
              v-if="inboundItem?.image"
              :src="inboundItem?.image?.url"
            />
            <v-img
              v-else
              class="me-6"
              src="@/assets/product.png"
              max-height="100"
              max-width="100"
            />
            <div class="me-4">
              <label class="ma-1 font-weight-regular">{{
                inboundItem?.name
              }}</label>
              <br />
              <label class="text--disabled ma-1">
                ID: {{ inboundItem.code }} . Barcode :
                {{ inboundItem.barcode }}</label
              >
              <br />
              <p class="ms-1 mt-2 font-weight-medium">
                {{ inboundItem?.qte_in_hub ?? 0 }} items in stock
              </p>
            </div>
            <div class="ml-auto me-6">
              <div
                class="
                  text-h5
                  font-weight-medium
                  d-flex
                  justify-space-between
                  py-4
                "
                style="min-width: 200px"
              >
                <span>Units per package</span>
                <span>{{ inboundItem?.product_info?.package_units }}</span>
              </div>
              <v-divider></v-divider>
              <div
                class="
                  text-h5
                  font-weight-medium
                  d-flex
                  justify-space-between
                  py-4
                "
                style="min-width: 200px"
              >
                <span>Package per palette</span>
                <span>{{ inboundItem?.product_info?.palette_units }}</span>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
          <v-row class="mt-9">
            <v-col class="d-flex px-4" cols="12" md="12" style="gap: 10px">
              <label class="my-auto">{{ $t("purchase") }}</label>
              <v-text-field
                :label="$t('purchase_price_ht')"
                dense
                hide-details
                outlined
                type="number"
                v-model="inboundItem.purchase_price_ht"
                :rules="[rules.required]"
              ></v-text-field>
              <v-select
                dense
                hide-details
                outlined
                :label="$t('purchase_tva')"
                v-model="inboundItem.purchase_tva"
                :rules="[rules.percentage]"
                :items="VATs"
              ></v-select>
              <v-text-field
                :label="$t('discount_%')"
                dense
                hide-details="auto"
                outlined
                type="number"
                v-model="inboundItem.discount"
                :rules="[rules.percentage]"
              ></v-text-field>
              <label class="ml-4 my-auto">{{ $t("selling") }}</label>
              <v-text-field
                :label="$t('selling_price_incl_tva')"
                dense
                hide-details="auto"
                outlined
                type="number"
                v-model="inboundItem.price"
                :rules="[rules.required, rules.superior_to(purchase_price)]"
              ></v-text-field>
              <v-select
                dense
                hide-details
                outlined
                :label="$t('tva')"
                v-model="inboundItem.tva"
                :rules="[rules.percentage]"
                :items="VATs"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="inboundItemsHeaders"
                :items="[inboundItem]"
                dense
                :hide-default-footer="true"
              >
                <template v-slot:item.margin="{ item }">
                  <span v-if="item">{{ margin }}</span>
                </template>
                <template v-slot:item.margin_objective="{ item }">
                  <!-- TODO: get the margin objective from the shelf  -->
                  <span v-if="item">{{ "-" }}</span>
                </template>
                <template v-slot:item.price_ht="{ item }">
                  <span v-if="item">{{ price_ht }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-checkbox
            dense
            hide-details
            class="ma-4"
            v-model="inboundItem.should_update_price"
            :label="$t('update_prices_for_item_card')"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-6"
            color="#6100ea"
            @click="updatePrice(inboundItem)"
            :disabled="!is_valid"
          >
            {{ this.$t("update_prices") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    isModalOpen: Boolean,
    inboundItem: Object,
    toggleModal: Function,
    updatePrice: Function,
  },

  computed: {
    ...mapGetters({
      priceSettings: "settings/priceSettings",
    }),
    price_ht() {
      return (
        this.inboundItem.price /
        (1 + this.inboundItem.tva / 100)
      ).toFixed(2);
    },
    purchase_price() {
      return (
        this.inboundItem.purchase_price_ht *
        (1 + this.inboundItem.purchase_tva / 100)
      ).toFixed(2);
    },

    margin() {
      if (!this.inboundItem.price_ht || !this.inboundItem.purchase_price_ht) {
        return "-";
      }
      return Number(
        (100 *
          (this.inboundItem.price_ht - this.inboundItem.purchase_price_ht)) /
          this.inboundItem.purchase_price_ht
      ).toFixed(2);
    },

    VATs() {
      return this.priceSettings.vat_array.map((vat) => ({
        text: vat + "%",
        value: vat,
      }));
    },
  },
  data() {
    return {
      is_valid: true,
      message: null,
      qte_by_type_packaging: 0,

      inboundItemsHeaders: [
        { text: this.$t("purchase_price_ht"), value: "purchase_price_ht" },
        { text: this.$t("purchase_tva"), value: "purchase_tva" },
        { text: this.$t("discount"), value: "discount" },
        { text: this.$t("price_ht"), value: "price_ht" },
        { text: this.$t("tva"), value: "tva" },
        { text: this.$t("margin"), value: "margin" },
        { text: this.$t("margin_objective"), value: "margin_objective" },
      ],
    };
  },
  watch: {},

  methods: {
    close() {
      this.toggleModal();
    },
  },
};
</script>
<style scoped>
.v-input {
  font-size: 0.95em;
}

.v-input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.v-input >>> input::-webkit-outer-spin-button,
.v-input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.5);
}
</style>